<template>
  <section @click.prevent="handlerSubmit(kycStatus.action)" class="kyc-status">
    <KycIcon :icon="kycStatus.icon" />
    <div class="kyc-status__body">
      <h2 class="subheadline-large">
        {{ kycStatus.title }}
      </h2>
      <div class="body-text-large">
        <p>
          {{ kycStatus.description }}
          <a href="#" @click.prevent="handlerSubmit(kycStatus.action)">{{ kycStatus.primaryButton }}</a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import KycStatusMixin from '@/mixins/kycStatus';
import KycIcon from '@/modules/user/KycIcon/index.vue';
export default {
  name: 'KycStatusBanner',
  mixins: [KycStatusMixin],
  components: {
    KycIcon
  },

  data() {
    return {};
  },

  methods: {
    hideToast() {
      this.$store.dispatch('ui/hideToast');
    },
    handlerSubmit(action) {
      if (action === 'close') {
        this.hideToast();
      }

      if(!this.isEmailVerified) {
        return this.showModal('EmailNotVerified');
      }

      this.showModal('KycStatus');
    },
    async loaded() {
      if (!this.kycLevel) {
        await this.loadUserPermissions();
        await this.loadKycLevel();
      }
      this.verifyKycLevel();
    },
  },

  computed: {
    ...mapGetters('user', [
      'isEmailVerified',
    ]),

    bgShadow() {
      return this.currentTheme === 'dark' ?
        'kyc-status__icon--dark' :
        'kyc-status__icon--light';
    }
  },

  mounted() {
    this.loaded();
  },
};
</script>

<style scoped>
.kyc-status {
  @apply flex py-s20 items-center;
}
.kyc-status a {
  @apply text-text-active-2;
}
.kyc-status__body {
  @apply ml-s16;
}

</style>
